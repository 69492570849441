import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { authQuery } from '../config/base-query';

import {
  ICreatorMessage,
  ICreatorMessageBody,
  ICreatorMessageResponseBody,
  ICreatorMessagesRequestBody,
} from 'app/(app)/creator.typings';
import dayjs from 'dayjs';
import { DATE_FORMATS } from '@ui/constants';
import { QUERIES } from '@constants/query.constants';
import { badgesApi } from './badges-api';
import { IImageUploadResponse } from 'typings';
import { creatorsApi } from './creators-api';

export const messagesAdapter = createEntityAdapter({
  selectId: (item: ICreatorMessage) => item.id,
});

export const messagesSelector = messagesAdapter.getSelectors();

export const messagesApi = createApi({
  reducerPath: 'messages-api',
  baseQuery: authQuery,
  tagTypes: [QUERIES.messagesV1],
  endpoints: (builder) => ({
    readMessage: builder.mutation<void, { params: ICreatorMessagesRequestBody; id: string }>({
      query({ id }) {
        return {
          url: 'notification',
          method: 'PATCH',
          body: {
            read: true,
            id,
          },
        };
      },
      async onQueryStarted({ id, params }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            messagesApi.util.updateQueryData('getMessages', params, (draft) => {
              messagesAdapter.updateOne(draft.messages, {
                id,
                changes: { isRead: true },
              });
            }),
          );
          dispatch(badgesApi.util.invalidateTags([QUERIES.creators]));
          dispatch(messagesApi.util.invalidateTags([QUERIES.messagesV1]));
          dispatch(creatorsApi.util.invalidateTags([QUERIES.creators]));  // TO DO, make this more specific
        } catch (e) {}
      },
    }),
    getMessages: builder.query<ICreatorMessageBody, ICreatorMessagesRequestBody>({
      query({ creatorId, page, pageSize }) {
        return {
          url: `/notifications?companyId=${creatorId}&page=${page}&pageSize=${pageSize}`,
        };
      },
      transformResponse({ notifications, pages }: ICreatorMessageResponseBody) {
        const messages = messagesAdapter.addMany(
          messagesAdapter.getInitialState(),
          notifications.map((item) => {
            return {
              title: item.name,
              id: item._id,
              message: item.description,
              richDescription: item?.richDescription,
              image: item.imageUrl,
              isRead: item.read,
              date: dayjs(item.updated_at).format(DATE_FORMATS.fanMessage), // TODO: add 'today'
            };
          }),
        );
        return {
          pages,
          messages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creatorId === previousArg?.creatorId;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameCreator || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.creatorId}`;
      },
      merge(currentState, incomingState) {
        messagesAdapter.addMany(
          currentState.messages,
          messagesSelector.selectAll(incomingState.messages),
        );
      },
      providesTags: [QUERIES.messagesV1],
    }),
    postImage: builder.mutation({
      query: (image: File) => {
        const form = new FormData();
        form.append('file', image);
        return {
          url: '/logo-upload',
          method: 'POST',
          body: form,
          formData: true,
        };
      },
      transformResponse(data: IImageUploadResponse) {
        return data.location;
      },
    }),
  }),
});

export const { useGetMessagesQuery, useReadMessageMutation, usePostImageMutation } = messagesApi;
